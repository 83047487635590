import Vue from 'vue';
import locale from 'element-ui/lib/locale/lang/zh-CN';
import {
  Cascader,
  CascaderPanel,
  Input,
  Select,
  Option,
  Message,
} from 'element-ui';
const components = [
  Cascader,
  CascaderPanel,
  Input,
  Select,
  Option,
  Message,
];

const Element = {
  install(Vue) {
    components.forEach((component) => {
      Vue.component(component.name, component);
    });
  }
};

Vue.use(Element, { locale });
Vue.prototype.$message = Message;
