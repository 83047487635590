import SteveModel from '@shell/plugins/steve/steve-class';

export default class ConfigMap extends SteveModel {
  get _availableActions() {
    // let out = super._availableActions;
    const out = [
      {
        action:  this.canUpdate ? 'goToEdit' : 'goToViewConfig',
        label:   this.t(this.canUpdate ? 'action.edit' : 'action.view'),
        icon:    'icon icon-edit',
        enabled:  this.canCustomEdit,
      },
      // {
      //   action:     'download',
      //   label:      this.t('action.download'),
      //   icon:       'icon icon-download',
      //   bulkable:   true,
      //   bulkAction: 'downloadBulk',
      //   enabled:    this.canYaml,
      //   weight:     -9,
      // },
      {
        action:     'promptRemove',
        altAction:  'remove',
        label:      this.t('action.remove'),
        icon:       'icon icon-trash',
        bulkable:   true,
        enabled:    this.canDelete,
        bulkAction: 'promptRemove',
        weight:     -10, // Delete always goes last
      },
    ];

    return out;
  }

  get keysDisplay() {
    const keys = [
      ...Object.keys(this.data || []),
      ...Object.keys(this.binaryData || [])
    ];

    if ( !keys.length ) {
      return '(none)';
    }

    // if ( keys.length >= 4 ) {
    //   return `${keys[0]}, ${keys[1]}, ${keys[2]} and ${keys.length - 3} more`;
    // }

    return keys.join(', ');
  }
}
