<template>
  <el-cascader
    v-model="filtered"
    class="project-cascader"
    popper-class="project-options"
    :popper-append-to-body="false"
    :options="options"
    :props="props"
    collapse-tags
    clearable
    @change="handleChange"
  >
  </el-cascader>
</template>

<script>
import { mapGetters } from 'vuex';
import { sortBy } from '@shell/utils/sort';
import { NAMESPACE_FILTERS, DEV } from '@shell/store/prefs';
import { NAMESPACE, MANAGEMENT } from '@shell/config/types';
import { isArray, addObjects, filterBy } from '@shell/utils/array';
import {
  NAMESPACE_FILTER_ALL_ORPHANS as ALL_ORPHANS,
  createNamespaceFilterKey,
  NAMESPACE_FILTER_ALL_USER as ALL_USER,
} from '@shell/utils/namespace-filter';
export default {
  data() {
    return {
      props: {
        multiple: true, value: 'id', showAllLevels: false, checkStrictly: false
      },
      filter:   '',
      filtered: []
    };
  },
  computed: {
    ...mapGetters(['currentProduct']),

    hasFilter() {
      return this.filter.length > 0;
    },

    key() {
      return createNamespaceFilterKey(this.$store.getters['clusterId'], this.currentProduct);
    },

    options() {
      const t = this.$store.getters['i18n/t'];
      const out = [];

      if (this.currentProduct.customNamespaceFilter) {
        // Sometimes the component can show before the 'currentProduct' has caught up, so access the product via the getter rather
        // than caching it in the `fetch`

        return this.$store.getters[`${ this.currentProduct.inStore }/namespaceFilterOptions`]({
          addNamespace,
          divider
        });
      }

      const inStore = this.$store.getters['currentStore'](NAMESPACE);
      let namespaces = sortBy(
        this.$store.getters[`${ inStore }/all`](NAMESPACE),
        ['nameDisplay']
      );

      namespaces = this.filterNamespaces(namespaces);

      // isRancher = mgmt schemas are loaded and there's a project schema
      if (this.$store.getters['isRancher']) {
        const cluster = this.$store.getters['currentCluster'];
        let projects = this.$store.getters['management/all'](
          MANAGEMENT.PROJECT
        );

        projects = projects.filter((p) => {
          return this.currentProduct?.hideSystemResources ? !p.isSystem && p.spec.clusterName === cluster.id : p.spec.clusterName === cluster.id;
        });
        projects = sortBy(filterBy(projects, 'spec.clusterName', cluster.id), [
          'nameDisplay',
        ]);
        const projectsById = {};
        const namespacesByProject = {};
        let firstProject = true;

        namespacesByProject[null] = []; // For namespaces not in a project
        for (const project of projects) {
          projectsById[project.metadata.name] = project;
        }

        for (const namespace of namespaces) {
          let projectId = namespace.projectId;

          if (!projectId || !projectsById[projectId]) {
            // If there's a projectId but that project doesn't exist, treat it like no project
            projectId = null;
          }

          let entry = namespacesByProject[projectId];

          if (!entry) {
            entry = [];
            namespacesByProject[namespace.projectId] = entry;
          }
          entry.push(namespace);
        }

        for (const project of projects) {
          const id = project.metadata.name;

          if (firstProject) {
            firstProject = false;
          } else {
            // divider(out);
          }
          const forThisProject = namespacesByProject[id] || [];
          const children = [];

          addNamespace(children, forThisProject);

          out.push({
            id:       `project://${ id }`,
            kind:     'project',
            label:    t('nav.ns.project', { name: project.nameDisplay }),
            children
          });

        //   addNamespace({
        //     id:       `project://${ id }`,
        //     kind:     'project',
        //     label:    t('nav.ns.project', { name: project.nameDisplay }),
        //     children: []
        //   }, forThisProject);
        }

        const orphans = namespacesByProject[null];

        if (orphans.length) {
          if (!firstProject) {
            // divider(out);
          }

          const children = [];

          addNamespace(children, orphans);

          out.push({
            id:       ALL_ORPHANS,
            kind:     'project',
            label:    t('nav.ns.orphan'),
            // disabled: true,
            children
          });

        //   addNamespace({
        //     id:       ALL_ORPHANS,
        //     kind:     'project',
        //     label:    t('nav.ns.orphan'),
        //     disabled: true,
        //     children: []
        //   }, orphans);
        }
      } else {
        console.log('else ');
        // addNamespace(out, namespaces);
      }

      return out;

      function addNamespace(out, namespaces) {
        if (!isArray(namespaces)) {
          namespaces = [namespaces];
        }

        addObjects(
          out,
          namespaces.map((namespace) => {
            return {
              id:    `ns://${ namespace.id }`,
              kind:  'namespace',
              label: t('nav.ns.namespace', { name: namespace.nameDisplay }),
            };
          })
        );
      }

      function divider(out) {
        out.push({
          kind:     'divider',
          label:    `Divider ${ out.length }`,
          disabled: true,
        });
      }
    }
  },
  methods: {
    filterNamespaces(namespaces) {
      if (this.$store.getters['prefs/get'](DEV)) {
        // If developer tools are turned on in the user preferences,
        // return all namespaces including system namespaces and RBAC
        // management namespaces.
        return namespaces;
      }

      const isVirtualCluster = this.$store.getters['isVirtualCluster'];

      return namespaces.filter((namespace) => {
        const isSettingSystemNamespace = this.$store.getters['systemNamespaces'].includes(namespace.metadata.name);
        const systemNS = namespace.isSystem || namespace.isFleetManaged || isSettingSystemNamespace;

        // For Harvester, filter out system namespaces AND obscure namespaces.
        if (isVirtualCluster) {
          return !systemNS && !namespace.isObscure;
        }

        // Otherwise only filter out obscure namespaces, such as namespaces
        // that Rancher uses to manage RBAC for projects, which should not be
        // edited or deleted by Rancher users.
        return !namespace.isObscure;
      });
    },
    getInitFilter() {
      const prefs = this.$store.getters['prefs/get'](NAMESPACE_FILTERS);
      const prefDefault = this.currentProduct.customNamespaceFilter ? [] : [ALL_USER];
      const values = prefs[this.key] || prefDefault;

      const options = this.options;

      const arr = [];

      for (let i = 0; i < values.length; i++) {
        if (values[i].substr(0, 1) !== 'ns') {
          // 放入项目id
          arr.push([values[i]]);
        }
        for (let j = 0; j < options.length; j++) {
          if (options[j].children) {
            options[j].children.forEach((item) => {
              if (item.id === values[i]) {
                arr.push([options[j].id, values[i]]);
              }
            });
          }
        }
      }

      // Remove values that are not valid options
      //   const out = values
      //     .map((value) => {
      //       return findBy(options, 'id', value);
      //     })
      //     .filter(x => !!x);

      return arr;
    },
    handleChange(e) {
      const ids = [];

      // 项目/命名空间分离
      e.forEach((item) => {
        if (item[0] && !item[1]) {
          ids.push(item[0]);
        } else {
          ids.push(item[1]);
        }
      });
      // const ids = [...new Set([...e].flat(Infinity))];

      this.$nextTick(() => {
        this.$store.dispatch('switchNamespaces', {
          ids,
          key: this.key
        });
      });
    }
  },
  created() {
    this.filtered = this.getInitFilter();
  },
  watch: {
    currentProduct() {
      this.filtered = this.getInitFilter();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .popper__arrow {
  ::after {
    display: none !important;
  }
  display: none !important;
}
.project-cascader {
  width: 250px;
  ::v-deep .el-cascader__tags .el-tag {
      max-width: 60%;
  }
  ::v-deep .el-input__inner {
      color: #fff;
      // background: #2D303D;
      background: #FFFFFF;
      border-radius: 2px;
      opacity: 0.15;
      border: none;
  }
  ::v-deep .el-input.is-focus .el-input__inner {
    border-radius: 2px;
    border: 1px solid #0077FF;
    opacity: 1;
    background-color: var(--header-bg) !important;
  }
  ::v-deep .el-tag__close {
      color: #fff;
  }
  ::v-deep .el-cascader__tags .el-tag .el-icon-close {
    background-color: unset;
  }
  ::v-deep .el-input__suffix {
    .is-reverse {
      transform: unset;
    }
    .el-icon-arrow-down:before {
      content: '\e778';
    }
  }
  //
  ::v-deep .el-tag.el-tag--info {
      color: #fff;
      // color: rgba(255,255,255,0.65);
      background: rgba(255,255,255,0.15);
  }
  ::v-deep .el-cascader-panel {
      background-color: var(--header-bg);
  }
  ::v-deep input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.65);
  }
  ::v-deep input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.65);
  }
  ::v-deep input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.65);
  }
  ::v-deep input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.65);
  }
}
</style>
<style lang="scss">
.project-options {
    .popper__arrow {
        display: none !important;
        filter: unset;
    }
    .el-cascader-panel {
        background-color: var(--header-bg) !important;
    }
    .el-cascader-menu {
        color: #fff;
    }
    .el-cascader-node:not(.is-disabled):focus, .el-cascader-node:not(.is-disabled):hover {
      background: #13151D;
    }
    .el-cascader-node {
      color: rgba(255,255,255,0.65);
    }
    .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
      color: #FFFFFF;
      font-weight: 400;
    }
    .el-checkbox__inner {
      background: #2D303D;
      border: 1px solid rgba(255,255,255,0.65);
    }
}
</style>
