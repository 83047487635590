const clusterSrc = require(`~shell/assets/images/nav/cluster.svg`);
const workloadSrc = require(`~shell/assets/images/nav/workload.svg`);
const storageSrc = require(`~shell/assets/images/nav/storage.svg`);
const serviceDiscoverySrc = require(`~shell/assets/images/nav/serviceDiscovery.svg`);
const apiSrc = require(`~shell/assets/images/nav/api.svg`);
const monitoringSrc = require(`~shell/assets/images/nav/monitoring.svg`);

export const sider = [
  {
    children: [
      {
        label:        '集群仪表板',
        labelDisplay: '集群仪表板',
        mode:         'basic',
        exact:        true,
        namespaced:   false,
        route:        {
          name:   'c-cluster-explorer',
          params: {
            cluster: 'c-d6v99',
            product: 'explorer'
          }
        },
        name:     'cluster-dashboard',
        weight:   100,
        overview: false
      },
      {
        label:        '项目/命名空间',
        labelDisplay: '项目/命名空间',
        mode:         'basic',
        exact:        true,
        namespaced:   false,
        route:        {
          name:   'c-cluster-product-projectsnamespaces',
          params: {
            cluster: 'c-d6v99',
            product: 'explorer'
          }
        },
        name:     'projects-namespaces',
        weight:   98,
        overview: false
      },
      {
        label:        '节点',
        labelDisplay: '节点',
        mode:         'basic',
        count:        2,
        exact:        false,
        namespaced:   false,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  'c-d6v99',
            resource: 'node'
          }
        },
        name:     'node',
        weight:   0,
        overview: false
      },
    //   {
    //     label:        '集群成员',
    //     labelDisplay: '集群成员',
    //     mode:         'basic',
    //     exact:        true,
    //     namespaced:   false,
    //     route:        {
    //       name:   'c-cluster-product-members',
    //       params: {
    //         cluster: 'c-d6v99',
    //         product: 'explorer'
    //       }
    //     },
    //     name:     'cluster-members',
    //     weight:   -1,
    //     overview: false
    //   }
    ],
    defaultType: undefined,
    label:       `<img src="${ clusterSrc }" /> 集群`,
    name:        'cluster',
    weight:      99
  },
  {
    name:     'workload',
    label:    `<img src="${ workloadSrc }" /> 工作负载`,
    weight:   98,
    children: [
    /* {
      label:        ' 工作负载 ',
      labelDisplay: '<i class="icon icon-fw icon-folder"></i> 工作负载 ',
      mode:         'basic',
      exact:        false,
      namespaced:   true,
      route:        {
        name:   'c-cluster-product-resource',
        params: {
          resource: 'workload',
          cluster:  'c-d6v99',
          product:  'explorer'
        }
      },
      name:     'workload',
      weight:   99,
      overview: false
    }, */
      {
        label:        'Deployments',
        labelDisplay: 'deployments',
        mode:         'basic',
        count:        24,
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  'c-d6v99',
            resource: 'apps.deployment'
          }
        },
        name:     'apps.deployment',
        weight:   0,
        overview: false
      },
      {
        label:        'Pod管理',
        labelDisplay: 'Pod管理',
        mode:         'basic',
        count:        25,
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  'c-d6v99',
            resource: 'pod'
          }
        },
        name:     'pod',
        weight:   -1,
        overview: false
      }, {
        label:        'StatefulSets',
        labelDisplay: 'StatefulSets',
        mode:         'basic',
        count:        1,
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  'c-d6v99',
            resource: 'apps.statefulset'
          }
        },
        name:     'apps.statefulset',
        weight:   0,
        overview: false
      }]
  },
  {
    name:        'serviceDiscovery',
    label:       `<img src="${ serviceDiscoverySrc }" /> 服务发现`,
    weight:      96,
    defaultType: 'service',
    children:    [{
      label:        'Ingresses管理',
      labelDisplay: 'Ingresses管理',
      mode:         'basic',
      count:        0,
      exact:        false,
      namespaced:   true,
      route:        {
        name:   'c-cluster-product-resource',
        params: {
          product:  'explorer',
          cluster:  'c-d6v99',
          resource: 'networking.k8s.io.ingress'
        }
      },
      name:     'networking.k8s.io.ingress',
      weight:   0,
      overview: false
    },
    {
      label:        'Services',
      labelDisplay: 'Services',
      mode:         'basic',
      count:        10,
      exact:        false,
      namespaced:   true,
      route:        {
        name:   'c-cluster-product-resource',
        params: {
          product:  'explorer',
          cluster:  'c-d6v99',
          resource: 'service'
        }
      },
      name:     'service',
      weight:   0,
      overview: false
    }]
  },
  {
    name:     'storage',
    label:    `<img src="${ storageSrc }" /> 存储`,
    weight:   95,
    children: [{
      label:        '持久卷',
      labelDisplay: '持久卷',
      mode:         'basic',
      count:        23,
      exact:        false,
      namespaced:   false,
      route:        {
        name:   'c-cluster-product-resource',
        params: {
          product:  'explorer',
          cluster:  'c-d6v99',
          resource: 'persistentvolume'
        }
      },
      name:     'persistentvolume',
      weight:   0,
      overview: false
    },
    {
      label:        '配置映射列表',
      labelDisplay: '配置映射列表',
      mode:         'basic',
      count:        3,
      exact:        false,
      namespaced:   true,
      route:        {
        name:   'c-cluster-product-resource',
        params: {
          product:  'explorer',
          cluster:  'c-d6v99',
          resource: 'configmap'
        }
      },
      name:     'configmap',
      weight:   0,
      overview: false
    },
    {
      label:        '持久卷声明',
      labelDisplay: '持久卷声明',
      mode:         'basic',
      count:        2,
      exact:        false,
      namespaced:   true,
      route:        {
        name:   'c-cluster-product-resource',
        params: {
          product:  'explorer',
          cluster:  'c-d6v99',
          resource: 'persistentvolumeclaim'
        }
      },
      name:     'persistentvolumeclaim',
      weight:   0,
      overview: false
    },
    {
      label:        '秘文',
      labelDisplay: '密文',
      mode:         'basic',
      count:        27,
      exact:        false,
      namespaced:   true,
      route:        {
        name:   'c-cluster-product-resource',
        params: {
          product:  'explorer',
          cluster:  'c-d6v99',
          resource: 'secret'
        }
      },
      name:     'secret',
      weight:   0,
      overview: false
    },
    ]
  }
];

export const getSider = (clusterId = 'local') => {
  return [
    {
      children: [
        {
          label:        '仪表盘',
          labelDisplay: '仪表盘',
          mode:         'basic',
          exact:        true,
          namespaced:   false,
          route:        {
            name:   'c-cluster-explorer',
            params: {
              cluster: clusterId,
              product: 'explorer'
            }
          },
          name:     'cluster-dashboard',
          weight:   100,
          overview: false
        },
        {
          label:        '项目/命名空间',
          labelDisplay: '项目/命名空间',
          mode:         'basic',
          exact:        true,
          namespaced:   false,
          route:        {
            name:   'c-cluster-product-projectsnamespaces',
            params: {
              cluster: clusterId,
              product: 'explorer'
            }
          },
          name:     'projects-namespaces',
          weight:   98,
          overview: false
        },
        {
          label:        '节点',
          labelDisplay: '节点',
          mode:         'basic',
          count:        2,
          exact:        false,
          namespaced:   false,
          route:        {
            name:   'c-cluster-product-resource',
            params: {
              product:  'explorer',
              cluster:  clusterId,
              resource: 'node'
            }
          },
          name:     'node',
          weight:   0,
          overview: false
        },
        // {
        //   label:        '集群成员',
        //   labelDisplay: '集群成员',
        //   mode:         'basic',
        //   exact:        true,
        //   namespaced:   false,
        //   route:        {
        //     name:   'c-cluster-product-members',
        //     params: {
        //       cluster: clusterId,
        //       product: 'explorer'
        //     }
        //   },
        //   name:     'cluster-members',
        //   weight:   -1,
        //   overview: false
        // }
      ],
      defaultType: undefined,
      label:       `<img src="${ clusterSrc }" /> 集群`,
      name:        'cluster',
      weight:      99
    },
    {
      name:     'workload',
      label:    `<img src="${ workloadSrc }" /> 工作负载`,
      weight:   98,
      children: [
      /* {
        label:        ' 工作负载 ',
        labelDisplay: '<i class="icon icon-fw icon-folder"></i> 工作负载 ',
        mode:         'basic',
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            resource: 'workload',
            cluster:  'c-d6v99',
            product:  'explorer'
          }
        },
        name:     'workload',
        weight:   99,
        overview: false
      }, */
        {
          label:        'Deployments',
          labelDisplay: 'Deployments',
          mode:         'basic',
          count:        24,
          exact:        false,
          namespaced:   true,
          route:        {
            name:   'c-cluster-product-resource',
            params: {
              product:  'explorer',
              cluster:  clusterId,
              resource: 'apps.deployment'
            }
          },
          name:     'apps.deployment',
          weight:   0,
          overview: false
        },
        {
          label:        'Pod管理',
          labelDisplay: 'Pod管理',
          mode:         'basic',
          count:        25,
          exact:        false,
          namespaced:   true,
          route:        {
            name:   'c-cluster-product-resource',
            params: {
              product:  'explorer',
              cluster:  clusterId,
              resource: 'pod'
            }
          },
          name:     'pod',
          weight:   -1,
          overview: false
        }, {
          label:        'StatefulSets',
          labelDisplay: 'StatefulSets',
          mode:         'basic',
          count:        1,
          exact:        false,
          namespaced:   true,
          route:        {
            name:   'c-cluster-product-resource',
            params: {
              product:  'explorer',
              cluster:  clusterId,
              resource: 'apps.statefulset'
            }
          },
          name:     'apps.statefulset',
          weight:   0,
          overview: false
        }]
    },
    {
      name:        'serviceDiscovery',
      label:       `<img src="${ serviceDiscoverySrc }" /> 服务发现`,
      weight:      96,
      defaultType: 'service',
      children:    [{
        label:        'Ingresses管理',
        labelDisplay: 'Ingresses管理',
        mode:         'basic',
        count:        0,
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  clusterId,
            resource: 'networking.k8s.io.ingress'
          }
        },
        name:     'networking.k8s.io.ingress',
        weight:   0,
        overview: false
      },
      {
        label:        'Services',
        labelDisplay: 'Services',
        mode:         'basic',
        count:        10,
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  clusterId,
            resource: 'service'
          }
        },
        name:     'service',
        weight:   0,
        overview: false
      },
      {
        label:        '弹性扩展配置',
        labelDisplay: '弹性扩展配置',
        mode:         'basic',
        count:        0,
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  clusterId,
            resource: 'autoscaling.horizontalpodautoscaler'
          }
        },
        name:     'autoscaling.horizontalpodautoscaler',
        weight:   0,
        overview: false
      }]
    },
    {
      name:     'storage',
      label:    `<img src="${ storageSrc }" /> 存储`,
      weight:   95,
      children: [{
        label:        '持久卷',
        labelDisplay: '持久卷',
        mode:         'basic',
        count:        23,
        exact:        false,
        namespaced:   false,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  clusterId,
            resource: 'persistentvolume'
          }
        },
        name:     'persistentvolume',
        weight:   0,
        overview: false
      },
      {
        label:        '配置映射列表',
        labelDisplay: '配置映射列表',
        mode:         'basic',
        count:        3,
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  clusterId,
            resource: 'configmap'
          }
        },
        name:     'configmap',
        weight:   0,
        overview: false
      },
      {
        label:        '持久卷声明',
        labelDisplay: '持久卷声明',
        mode:         'basic',
        count:        2,
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  clusterId,
            resource: 'persistentvolumeclaim'
          }
        },
        name:     'persistentvolumeclaim',
        weight:   0,
        overview: false
      },
      {
        label:        '秘文',
        labelDisplay: '密文',
        mode:         'basic',
        count:        27,
        exact:        false,
        namespaced:   true,
        route:        {
          name:   'c-cluster-product-resource',
          params: {
            product:  'explorer',
            cluster:  clusterId,
            resource: 'secret'
          }
        },
        name:     'secret',
        weight:   0,
        overview: false
      },
      ]
    },
    {
      name:     'API',
      label:    `<img src="${ apiSrc }" /> API`,
      weight:   0,
      children: [
        {
          count:        43,
          label:        'APIServices',
          labelDisplay: 'API服务',
          exact:        false,
          namespaced:   true,
          overview:     false,
          mode:         'basic',
          name:         'apiregistration.k8s.io.apiservice',
          route:        {
            name:   'c-cluster-product-resource',
            params: {
              product:  'explorer',
              cluster:  clusterId,
              resource: 'apiregistration.k8s.io.apiservice'
            }
          },
          weight: 0
        },
        {
          count:        43,
          label:        'CustomResourceDefinitions',
          labelDisplay: '自定义资源定义',
          exact:        false,
          namespaced:   false,
          overview:     false,
          mode:         'basic',
          name:         'apiextensions.k8s.io.customresourcedefinition',
          route:        {
            name:   'c-cluster-product-resource',
            params: {
              product:  'explorer',
              cluster:  clusterId,
              resource: 'apiextensions.k8s.io.customresourcedefinition'
            }
          },
          weight: 0
        },
        {
          count:        43,
          label:        'FlowSchemas',
          labelDisplay: '流模式',
          exact:        false,
          namespaced:   true,
          overview:     false,
          mode:         'basic',
          name:         'flowcontrol.apiserver.k8s.io.flowschema',
          route:        {
            name:   'c-cluster-product-resource',
            params: {
              product:  'explorer',
              cluster:  clusterId,
              resource: 'flowcontrol.apiserver.k8s.io.flowschema'
            }
          },
          weight: 0
        },
        {
          count:        43,
          label:        'PriorityLevelConfigurations',
          labelDisplay: '优先级配置',
          exact:        false,
          namespaced:   true,
          overview:     false,
          mode:         'basic',
          name:         'flowcontrol.apiserver.k8s.io.prioritylevelconfiguration',
          route:        {
            name:   'c-cluster-product-resource',
            params: {
              product:  'explorer',
              cluster:  clusterId,
              resource: 'flowcontrol.apiserver.k8s.io.prioritylevelconfiguration'
            }
          },
          weight: 0
        },
      ]
    },
    {
      label:    `<img src="${ monitoringSrc }" /> 监控`,
      name:     'monitoring',
      weight:   90,
      children: [
        {
          count:        undefined,
          label:        'Monitoring',
          labelDisplay: '仪表板',
          exact:        true,
          namespaced:   false,
          overview:     false,
          mode:         'basic',
          name:         'monitoring-overview',
          route:        {
            name:   'c-cluster-monitoring',
            params: {
              product:  'monitoring',
              cluster:  clusterId,
              // resource: 'flowcontrol.apiserver.k8s.io.prioritylevelconfiguration'
            }
          },
          weight: 105
        },
        {
          count:        undefined,
          label:        'Monitors',
          labelDisplay: '监控',
          exact:        false,
          namespaced:   undefined,
          overview:     false,
          mode:         'basic',
          name:         'monitor',
          route:        {
            name:   'c-cluster-monitoring-monitor',
            params: {
              product:  'monitoring',
              cluster:  clusterId,
              // resource: 'flowcontrol.apiserver.k8s.io.prioritylevelconfiguration'
            }
          },
          weight: 1
        },
        {
          label:    '警报',
          name:     'Alerting',
          weight:   0,
          children: [
            {
              count:        0,
              label:        'AlertmanagerConfig',
              labelDisplay: '警报管理配置',
              exact:        false,
              namespaced:   true,
              overview:     false,
              mode:         'basic',
              name:         'monitoring.coreos.com.alertmanagerconfig',
              route:        {
                name:   'c-cluster-product-resource',
                params: {
                  product:  'monitoring',
                  cluster:  clusterId,
                  resource: 'monitoring.coreos.com.alertmanagerconfig'
                }
              },
              weight: 2
            },
            {
              count:        0,
              label:        'Routes and Receivers',
              labelDisplay: '路由和接收器',
              exact:        false,
              namespaced:   undefined,
              overview:     false,
              mode:         'basic',
              name:         'route-receiver',
              route:        {
                name:   'c-cluster-monitoring-route-receiver',
                params: {
                  product:  'monitoring',
                  cluster:  clusterId,
                }
              },
              weight: 2
            }
          ]
        },
        {
          label:       '高级设置',
          name:        'Advanced',
          weight:      -1,
          defaultType: undefined,
          children:    [
            {
              count:        0,
              label:        'PrometheusRule',
              labelDisplay: 'PrometheusRule',
              exact:        false,
              namespaced:   true,
              overview:     false,
              mode:         'basic',
              name:         'monitoring.coreos.com.prometheusrule',
              route:        {
                name:   'c-cluster-product-resource',
                params: {
                  product:  'monitoring',
                  cluster:  clusterId,
                  resource: 'monitoring.coreos.com.prometheusrule'
                }
              },
              weight: 102
            },
            {
              count:        0,
              label:        'Prometheus',
              labelDisplay: 'Prometheus',
              exact:        false,
              namespaced:   undefined,
              overview:     false,
              mode:         'basic',
              name:         'route-receiver',
              route:        {
                name:   'c-cluster-product-resource',
                params: {
                  product:  'monitoring',
                  cluster:  clusterId,
                  resource: 'monitoring.coreos.com.prometheus'
                }
              },
              weight: 0
            }
          ]
        }
      ]
    }
  ];
};
