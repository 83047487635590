<template>
  <div>
    <el-select
      v-model="activeCluster"
      class="cluster-select"
      popper-class="cluster-options"
      :popper-append-to-body="false"
    >
      <el-option
        v-for="item in clusters"
        :key="item.id"
        :label="item.label"
        :value="item.id"
      >
        <nuxt-link
          v-if="item.ready"
          :to="{ name: 'c-cluster', params: { cluster: item.id } }"
        >
          <div class="cluster-name">
            {{ item.label }}
          </div>
        </nuxt-link>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { CAPI, MANAGEMENT } from '@shell/config/types';
import { filterOnlyKubernetesClusters, filterHiddenLocalCluster } from '@shell/utils/cluster';
import { createNamespaceFilterKey } from '@shell/utils/namespace-filter';
export default {
  data() {
    return { activeCluster: createNamespaceFilterKey(this.$store.getters['clusterId'], this.currentProduct) };
  },
  computed: {
    clusters() {
      const all = this.$store.getters['management/all'](MANAGEMENT.CLUSTER);
      let kubeClusters = filterHiddenLocalCluster(filterOnlyKubernetesClusters(all), this.$store);

      if (this.hasProvCluster) {
        const pClusters = this.$store.getters['management/all'](CAPI.RANCHER_CLUSTER);
        const available = pClusters.reduce((p, c) => {
          p[c.mgmt] = true;

          return p;
        }, {});

        // Filter to only show mgmt clusters that exist for the available provisionning clusters
        // Addresses issue where a mgmt cluster can take some time to get cleaned up after the corresponding
        // provisionning cluster has been deleted
        kubeClusters = kubeClusters.filter(c => !!available[c]);
      }

      return kubeClusters.map((x) => {
        return {
          id:              x.id,
          label:           x.nameDisplay,
          ready:           x.isReady,
          osLogo:          x.providerOsLogo,
          providerNavLogo: x.providerMenuLogo,
          badge:           x.badge,
          isLocal:         x.isLocal
        };
      });
    },
    // key() {
    //   console.log('key', createNamespaceFilterKey(this.$store.getters['clusterId'], this.currentProduct));

    //   return createNamespaceFilterKey(this.$store.getters['clusterId'], this.currentProduct);
    // }
  }
};
</script>

<style lang="scss" scoped>
.cluster-select {
  height: 40px;
  width: 120px;
  background-color: var(--header-bg);
  ::v-deep .el-input__inner {
    color: #fff;
    background-color: var(--header-bg);
    border: none;
    text-align: right;
  }
  ::v-deep .el-input__suffix {
    .el-select__caret:before {
      content: '\e790';
    }
  }
}

::v-deep .cluster-options {
  background-color: var(--header-bg);
  color: #FFFFFF;
  .popper__arrow {
    display: none;
  }
  .el-select-dropdown__item {
    .nuxt-link-active {
      color: #FFFFFF;
    }
    A {
      font-weight: 400;
      color: rgba(255,255,255,0.65);
      text-decoration: none;
    }
  }
  .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background: #13151D;
  }
}
</style>
