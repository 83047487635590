<script>
import SortableTable from '@shell/components/SortableTable';
import { _EDIT, _VIEW } from '@shell/config/query-params';
import { SECRET } from '@shell/config/types';
import ArrayListGrouped from '@shell/components/form/ArrayListGrouped';
import Certificate from './Certificate';
import Collapse from '@shell/components/Collapse.vue';
import { random32 } from '@shell/utils/string';

export default {
  components: {
    ArrayListGrouped, Certificate, SortableTable, Collapse
  },
  props:      {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: _EDIT
    },
    certificates: {
      type:    Array,
      default: () => []
    },
    rules: {
      default: () => ({ host: [] }),
      type:    Object,
    }
  },
  data() {
    return {
      defaultAddValue: {
        secretName:  null,
        hosts:       [''],
        vKey:       random32(1),
      }
    };
  },
  beforeUpdate() {
    console.log(33332222);
    for (const cert of this.value.spec.tls) {
      console.log(this.value.spec.tls, '32', cert, cert.vKey);
      if (!cert.vKey) {
        console.log('是否无数据');
        this.$set(cert, 'vKey', random32(1));
      }
    }
  },
  computed: {
    isView() {
      return this.mode === _VIEW;
    },
    certHeaders() {
      return [
        {
          name:      'certificate',
          label:     this.t('ingress.certificates.headers.certificate'),
          value:     'link',
          formatter: 'Link',
          sort:      'link.text'
        },
        {
          name:      'hosts',
          label:     this.t('ingress.certificates.headers.hosts'),
          value:     'hosts',
          formatter: 'list'
        },
      ];
    },
    certRows() {
      return this.value?.spec?.tls.map((cert) => {
        if (cert.secretName) {
          const name = 'c-cluster-resource-namespace-id';
          const params = {
            namespace: this.value?.metadata?.namespace,
            id:        cert.secretName,
            resource:  SECRET
          };
          const url = { name, params };

          cert.link = { url, text: cert.secretName };
        } else {
          cert.link = { text: 'default' };
        }

        return cert;
      });
    }
  },
  methods: {
    openChange(val, row) {
      console.log(val, row, '23');
      const openRow = { ...row, open: val };
      const index = this.value.spec.tls.findIndex(item => item.vKey === row.vKey);

      if (index >= 0) {
        this.$nextTick(() => {
          this.$set(this.value.spec.tls, index, openRow);
        });
      }
    }
  }
};
</script>
<template>
  <SortableTable
    v-if="isView"
    :rows="certRows"
    :headers="certHeaders"
    key-field="_key"
    :search="false"
    :table-actions="false"
    :row-actions="false"
  />
  <div v-else>
    <ArrayListGrouped v-model="value.spec.tls" :top-button="true" :add-label="t('ingress.certificates.addCertificate')" :default-add-value="defaultAddValue" :mode="mode">
      <template #default="props">
        <Collapse
          :key="props.row.value.vKey"
          :open.sync="props.row.value.open"
          class="mb-10"
          @update:open="(val)=>openChange(val, props.row.value)"
        >
          <Certificate
            :key="props.row.value.vKey"
            v-model="props.row.value"
            class="mb-20"
            :mode="mode"
            :certs="certificates"
            :rules="rules"
          />
        </Collapse>
      </template>
    </ArrayListGrouped>
  </div>
</template>

<style lang="scss" scoped>
.collapse {
  // background: #FAFBFB;
  ::v-deep .text-link {
    color: #161C24 !important;
  }
  ::v-deep .content {
    background: #ffffff;
  }
}
::v-deep .remove {
  top: 12px !important;
  right:10px !important;
  .role-link {
    color: #96999C !important;
  }
}
::v-deep .labeled-input INPUT.no-label {
  padding: 0;
}

</style>
