<script>
const flat = require(`~shell/assets/images/nav/button/flat.svg`);
const folder = require(`~shell/assets/images/nav/button/folder.svg`);
const cluster = require(`~shell/assets/images/nav/button/cluster.svg`);

export default {
  props: {
    value: {
      type:     [String, Number, Boolean, Object],
      required: true,
    },

    inactiveClass: {
      type:    String,
      default: 'bg-disabled',
    },

    activeClass: {
      type:    String,
      default: 'bg-primary',
    },

    options: {
      type:     Array,
      required: true,
    },

    iconSize: {
      type:    String,
      default: null,
    },

    disabled: {
      type:     Boolean,
      default: false,
    }

  },
  data() {
    return {
      flat, folder, cluster
    };
  },

  computed: {
    optionObjects() {
      const value = this.value;

      return this.options.map((opt) => {
        let out;

        if ( opt && typeof opt === 'object' && typeof opt.value !== 'undefined' ) {
          out = Object.assign({}, opt);
        } else {
          out = { label: opt, value: opt };
        }

        const active = value === out.value;

        out.class = {
          btn:                  true,
          [this.inactiveClass]: !active,
          [this.activeClass]:   active,
        };

        return out;
      });
    },
  },

  methods: {
    change(value) {
      this.$emit('input', value);
    },
    // 临时处理，后期加入svgIcon组件后可替换IMG标签
    isIcon(icon) {
      switch (icon) {
      case 'icon-list-flat':
        return flat;
      case 'icon-folder':
        return folder;
      case 'icon-cluster':
        return cluster;
      default:
        return false;
      }
    }
  }
};
</script>

<template>
  <div v-trim-whitespace class="btn-group">
    <button
      v-for="(opt,idx) in optionObjects"
      :key="idx"
      v-tooltip="opt.tooltipKey ? t(opt.tooltipKey) : opt.tooltip"
      type="button"
      :class="opt.class"
      :disabled="disabled || opt.disabled"
      @click="change(opt.value)"
    >
      <slot name="option" :label="opt.label" :value="opt.value">
        <img v-if="opt.icon && isIcon(opt.icon)" :src="isIcon(opt.icon)">
        <i v-if="opt.icon && !isIcon(opt.icon)" :class="{icon: true, [opt.icon]: true, [`icon-${iconSize}`]: !!iconSize }" />
        <t v-if="opt.labelKey" :k="opt.labelKey" />
        <span v-else-if="opt.label">{{ opt.label }}</span>
      </slot>
    </button>
  </div>
</template>

<style lang="scss" scoped>
  .bg-primary {
    ::v-deep {
      IMG {
        filter: invert(0.5) sepia(1) saturate(10) hue-rotate(200deg) !important;
      }
    }
  }
</style>
