import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cbfdecf2 = () => interopDefault(import('../shell/pages/about.vue' /* webpackChunkName: "shell/pages/about" */))
const _36f01cea = () => interopDefault(import('../shell/pages/account/index.vue' /* webpackChunkName: "shell/pages/account/index" */))
const _5bbcd920 = () => interopDefault(import('../shell/pages/c/index.vue' /* webpackChunkName: "shell/pages/c/index" */))
const _02343df2 = () => interopDefault(import('../shell/pages/clusters/index.vue' /* webpackChunkName: "shell/pages/clusters/index" */))
const _31e903c6 = () => interopDefault(import('../shell/pages/diagnostic.vue' /* webpackChunkName: "shell/pages/diagnostic" */))
const _ccaeaaa0 = () => interopDefault(import('../shell/pages/fail-whale.vue' /* webpackChunkName: "shell/pages/fail-whale" */))
const _6d10cc56 = () => interopDefault(import('../shell/pages/home.vue' /* webpackChunkName: "shell/pages/home" */))
const _547b2b9a = () => interopDefault(import('../shell/pages/plugins.vue' /* webpackChunkName: "shell/pages/plugins" */))
const _c51d81ac = () => interopDefault(import('../shell/pages/prefs.vue' /* webpackChunkName: "shell/pages/prefs" */))
const _8e4c1fe8 = () => interopDefault(import('../shell/pages/support/index.vue' /* webpackChunkName: "shell/pages/support/index" */))
const _79216726 = () => interopDefault(import('../shell/pages/account/create-key.vue' /* webpackChunkName: "shell/pages/account/create-key" */))
const _6d2f9138 = () => interopDefault(import('../shell/pages/auth/login.vue' /* webpackChunkName: "shell/pages/auth/login" */))
const _f458c36a = () => interopDefault(import('../shell/pages/auth/logout.vue' /* webpackChunkName: "shell/pages/auth/logout" */))
const _308a324c = () => interopDefault(import('../shell/pages/auth/setup.vue' /* webpackChunkName: "shell/pages/auth/setup" */))
const _65fb01ba = () => interopDefault(import('../shell/pages/auth/verify.vue' /* webpackChunkName: "shell/pages/auth/verify" */))
const _93608936 = () => interopDefault(import('../shell/pages/docs/toc.js' /* webpackChunkName: "shell/pages/docs/toc" */))
const _296bc7fa = () => interopDefault(import('../shell/pages/rio/mesh.vue' /* webpackChunkName: "shell/pages/rio/mesh" */))
const _3c6fa820 = () => interopDefault(import('../shell/pages/c/_cluster/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/index" */))
const _a589d672 = () => interopDefault(import('../shell/pages/docs/_doc.vue' /* webpackChunkName: "shell/pages/docs/_doc" */))
const _2bd46717 = () => interopDefault(import('../shell/pages/c/_cluster/apps/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/index" */))
const _e5532266 = () => interopDefault(import('../shell/pages/c/_cluster/auth/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/index" */))
const _108e53e7 = () => interopDefault(import('../shell/pages/c/_cluster/backup/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/backup/index" */))
const _cf2b7344 = () => interopDefault(import('../shell/pages/c/_cluster/cis/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/cis/index" */))
const _6c2b7ac0 = () => interopDefault(import('../shell/pages/c/_cluster/ecm/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/ecm/index" */))
const _ce3878b8 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/index" */))
const _7e48001f = () => interopDefault(import('../shell/pages/c/_cluster/fleet/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/fleet/index" */))
const _989be23c = () => interopDefault(import('../shell/pages/c/_cluster/gatekeeper/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/gatekeeper/index" */))
const _398da45c = () => interopDefault(import('../shell/pages/c/_cluster/harvesterManager/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/harvesterManager/index" */))
const _2e3188fe = () => interopDefault(import('../shell/pages/c/_cluster/istio/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/istio/index" */))
const _2f94352e = () => interopDefault(import('../shell/pages/c/_cluster/legacy/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/index" */))
const _31c0b770 = () => interopDefault(import('../shell/pages/c/_cluster/logging/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/logging/index" */))
const _93574d38 = () => interopDefault(import('../shell/pages/c/_cluster/longhorn/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/longhorn/index" */))
const _cc671b84 = () => interopDefault(import('../shell/pages/c/_cluster/manager/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/index" */))
const _1ad9c8a6 = () => interopDefault(import('../shell/pages/c/_cluster/mcapps/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/mcapps/index" */))
const _4ad6bded = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/index" */))
const _26165872 = () => interopDefault(import('../shell/pages/c/_cluster/neuvector/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/neuvector/index" */))
const _506e9a88 = () => interopDefault(import('../shell/pages/c/_cluster/settings/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/index" */))
const _1cbc183a = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/index" */))
const _a81763cc = () => interopDefault(import('../shell/pages/c/_cluster/auth/config/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/config/index" */))
const _aea9b88a = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/index" */))
const _3957f133 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/ConfigBadge.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/ConfigBadge" */))
const _4c3c7032 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/EventsTable.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/EventsTable" */))
const _90662ea4 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/explorer-utils.js' /* webpackChunkName: "shell/pages/c/_cluster/explorer/explorer-utils" */))
const _773eae20 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/tools/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/tools/index" */))
const _15fd8760 = () => interopDefault(import('../shell/pages/c/_cluster/fleet/GitRepoGraphConfig.js' /* webpackChunkName: "shell/pages/c/_cluster/fleet/GitRepoGraphConfig" */))
const _158e8b6e = () => interopDefault(import('../shell/pages/c/_cluster/gatekeeper/constraints/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/gatekeeper/constraints/index" */))
const _6a86f5b8 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/index" */))
const _d3a8c74a = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/index" */))
const _3fc8876a = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/index" */))
const _779ba478 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/index" */))
const _6ec75e6b = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/index" */))
const _6d76467d = () => interopDefault(import('../shell/pages/c/_cluster/settings/banners.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/banners" */))
const _51a35abd = () => interopDefault(import('../shell/pages/c/_cluster/settings/brand.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/brand" */))
const _55ced734 = () => interopDefault(import('../shell/pages/c/_cluster/settings/performance.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/performance" */))
const _fff78622 = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/chart.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/chart" */))
const _7f98e9ec = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/install.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/install" */))
const _98b7ff5a = () => interopDefault(import('../shell/pages/c/_cluster/auth/group.principal/assign-edit.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/group.principal/assign-edit" */))
const _57f12f12 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/pipelines.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/pipelines" */))
const _56fc39c3 = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/create" */))
const _b8e59574 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/create" */))
const _dc4e8c9a = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/create" */))
const _07764081 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/tools/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/tools/pages/_page" */))
const _54930f02 = () => interopDefault(import('../shell/pages/c/_cluster/auth/config/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/config/_id" */))
const _8ed9b382 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/pages/_page" */))
const _596f8b58 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/_page" */))
const _411af983 = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/_id" */))
const _79655162 = () => interopDefault(import('../shell/pages/c/_cluster/manager/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/pages/_page" */))
const _46f61d04 = () => interopDefault(import('../shell/pages/c/_cluster/mcapps/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/mcapps/pages/_page" */))
const _6349ee89 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/index" */))
const _76dfff93 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/_id" */))
const _679c4d85 = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/_resource/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/_resource/create" */))
const _1abbd888 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/receiver.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/receiver" */))
const _35ef4d01 = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/_resource/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/_resource/_id" */))
const _aa2ed45e = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/_namespace/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/_namespace/_id" */))
const _1a8e4d87 = () => interopDefault(import('../shell/pages/c/_cluster/navlinks/_group.vue' /* webpackChunkName: "shell/pages/c/_cluster/navlinks/_group" */))
const _7a4705d5 = () => interopDefault(import('../shell/pages/c/_cluster/_product/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/index" */))
const _28f1a542 = () => interopDefault(import('../shell/pages/c/_cluster/_product/members/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/members/index" */))
const _c69ed6f6 = () => interopDefault(import('../shell/pages/c/_cluster/_product/namespaces.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/namespaces" */))
const _347feb02 = () => interopDefault(import('../shell/pages/c/_cluster/_product/projectsnamespaces.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/projectsnamespaces" */))
const _781c91f3 = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/index" */))
const _99ac11aa = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/create" */))
const _950715ca = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/_id" */))
const _d128ba94 = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/_namespace/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/_namespace/_id" */))
const _8c8e3768 = () => interopDefault(import('../shell/pages/index.vue' /* webpackChunkName: "shell/pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/dashboard/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _cbfdecf2,
    name: "about"
  }, {
    path: "/account",
    component: _36f01cea,
    name: "account"
  }, {
    path: "/c",
    component: _5bbcd920,
    name: "c"
  }, {
    path: "/clusters",
    component: _02343df2,
    name: "clusters"
  }, {
    path: "/diagnostic",
    component: _31e903c6,
    name: "diagnostic"
  }, {
    path: "/fail-whale",
    component: _ccaeaaa0,
    name: "fail-whale"
  }, {
    path: "/home",
    component: _6d10cc56,
    name: "home"
  }, {
    path: "/plugins",
    component: _547b2b9a,
    name: "plugins"
  }, {
    path: "/prefs",
    component: _c51d81ac,
    name: "prefs"
  }, {
    path: "/support",
    component: _8e4c1fe8,
    name: "support"
  }, {
    path: "/account/create-key",
    component: _79216726,
    name: "account-create-key"
  }, {
    path: "/auth/login",
    component: _6d2f9138,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _f458c36a,
    name: "auth-logout"
  }, {
    path: "/auth/setup",
    component: _308a324c,
    name: "auth-setup"
  }, {
    path: "/auth/verify",
    component: _65fb01ba,
    name: "auth-verify"
  }, {
    path: "/docs/toc",
    component: _93608936,
    name: "docs-toc"
  }, {
    path: "/rio/mesh",
    component: _296bc7fa,
    name: "rio-mesh"
  }, {
    path: "/c/:cluster",
    component: _3c6fa820,
    name: "c-cluster"
  }, {
    path: "/docs/:doc?",
    component: _a589d672,
    name: "docs-doc"
  }, {
    path: "/c/:cluster/apps",
    component: _2bd46717,
    name: "c-cluster-apps"
  }, {
    path: "/c/:cluster/auth",
    component: _e5532266,
    name: "c-cluster-auth"
  }, {
    path: "/c/:cluster/backup",
    component: _108e53e7,
    name: "c-cluster-backup"
  }, {
    path: "/c/:cluster/cis",
    component: _cf2b7344,
    name: "c-cluster-cis"
  }, {
    path: "/c/:cluster/ecm",
    component: _6c2b7ac0,
    name: "c-cluster-ecm"
  }, {
    path: "/c/:cluster/explorer",
    component: _ce3878b8,
    name: "c-cluster-explorer"
  }, {
    path: "/c/:cluster/fleet",
    component: _7e48001f,
    name: "c-cluster-fleet"
  }, {
    path: "/c/:cluster/gatekeeper",
    component: _989be23c,
    name: "c-cluster-gatekeeper"
  }, {
    path: "/c/:cluster/harvesterManager",
    component: _398da45c,
    name: "c-cluster-harvesterManager"
  }, {
    path: "/c/:cluster/istio",
    component: _2e3188fe,
    name: "c-cluster-istio"
  }, {
    path: "/c/:cluster/legacy",
    component: _2f94352e,
    name: "c-cluster-legacy"
  }, {
    path: "/c/:cluster/logging",
    component: _31c0b770,
    name: "c-cluster-logging"
  }, {
    path: "/c/:cluster/longhorn",
    component: _93574d38,
    name: "c-cluster-longhorn"
  }, {
    path: "/c/:cluster/manager",
    component: _cc671b84,
    name: "c-cluster-manager"
  }, {
    path: "/c/:cluster/mcapps",
    component: _1ad9c8a6,
    name: "c-cluster-mcapps"
  }, {
    path: "/c/:cluster/monitoring",
    component: _4ad6bded,
    name: "c-cluster-monitoring"
  }, {
    path: "/c/:cluster/neuvector",
    component: _26165872,
    name: "c-cluster-neuvector"
  }, {
    path: "/c/:cluster/settings",
    component: _506e9a88,
    name: "c-cluster-settings"
  }, {
    path: "/c/:cluster/apps/charts",
    component: _1cbc183a,
    name: "c-cluster-apps-charts"
  }, {
    path: "/c/:cluster/auth/config",
    component: _a81763cc,
    name: "c-cluster-auth-config"
  }, {
    path: "/c/:cluster/auth/roles",
    component: _aea9b88a,
    name: "c-cluster-auth-roles"
  }, {
    path: "/c/:cluster/explorer/ConfigBadge",
    component: _3957f133,
    name: "c-cluster-explorer-ConfigBadge"
  }, {
    path: "/c/:cluster/explorer/EventsTable",
    component: _4c3c7032,
    name: "c-cluster-explorer-EventsTable"
  }, {
    path: "/c/:cluster/explorer/explorer-utils",
    component: _90662ea4,
    name: "c-cluster-explorer-explorer-utils"
  }, {
    path: "/c/:cluster/explorer/tools",
    component: _773eae20,
    name: "c-cluster-explorer-tools"
  }, {
    path: "/c/:cluster/fleet/GitRepoGraphConfig",
    component: _15fd8760,
    name: "c-cluster-fleet-GitRepoGraphConfig"
  }, {
    path: "/c/:cluster/gatekeeper/constraints",
    component: _158e8b6e,
    name: "c-cluster-gatekeeper-constraints"
  }, {
    path: "/c/:cluster/legacy/project",
    component: _6a86f5b8,
    name: "c-cluster-legacy-project"
  }, {
    path: "/c/:cluster/manager/cloudCredential",
    component: _d3a8c74a,
    name: "c-cluster-manager-cloudCredential"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig",
    component: _3fc8876a,
    name: "c-cluster-monitoring-alertmanagerconfig"
  }, {
    path: "/c/:cluster/monitoring/monitor",
    component: _779ba478,
    name: "c-cluster-monitoring-monitor"
  }, {
    path: "/c/:cluster/monitoring/route-receiver",
    component: _6ec75e6b,
    name: "c-cluster-monitoring-route-receiver"
  }, {
    path: "/c/:cluster/settings/banners",
    component: _6d76467d,
    name: "c-cluster-settings-banners"
  }, {
    path: "/c/:cluster/settings/brand",
    component: _51a35abd,
    name: "c-cluster-settings-brand"
  }, {
    path: "/c/:cluster/settings/performance",
    component: _55ced734,
    name: "c-cluster-settings-performance"
  }, {
    path: "/c/:cluster/apps/charts/chart",
    component: _fff78622,
    name: "c-cluster-apps-charts-chart"
  }, {
    path: "/c/:cluster/apps/charts/install",
    component: _7f98e9ec,
    name: "c-cluster-apps-charts-install"
  }, {
    path: "/c/:cluster/auth/group.principal/assign-edit",
    component: _98b7ff5a,
    name: "c-cluster-auth-group.principal-assign-edit"
  }, {
    path: "/c/:cluster/legacy/project/pipelines",
    component: _57f12f12,
    name: "c-cluster-legacy-project-pipelines"
  }, {
    path: "/c/:cluster/manager/cloudCredential/create",
    component: _56fc39c3,
    name: "c-cluster-manager-cloudCredential-create"
  }, {
    path: "/c/:cluster/monitoring/monitor/create",
    component: _b8e59574,
    name: "c-cluster-monitoring-monitor-create"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/create",
    component: _dc4e8c9a,
    name: "c-cluster-monitoring-route-receiver-create"
  }, {
    path: "/c/:cluster/explorer/tools/pages/:page?",
    component: _07764081,
    name: "c-cluster-explorer-tools-pages-page"
  }, {
    path: "/c/:cluster/auth/config/:id",
    component: _54930f02,
    name: "c-cluster-auth-config-id"
  }, {
    path: "/c/:cluster/legacy/pages/:page?",
    component: _8ed9b382,
    name: "c-cluster-legacy-pages-page"
  }, {
    path: "/c/:cluster/legacy/project/:page",
    component: _596f8b58,
    name: "c-cluster-legacy-project-page"
  }, {
    path: "/c/:cluster/manager/cloudCredential/:id",
    component: _411af983,
    name: "c-cluster-manager-cloudCredential-id"
  }, {
    path: "/c/:cluster/manager/pages/:page?",
    component: _79655162,
    name: "c-cluster-manager-pages-page"
  }, {
    path: "/c/:cluster/mcapps/pages/:page?",
    component: _46f61d04,
    name: "c-cluster-mcapps-pages-page"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig/:alertmanagerconfigid",
    component: _6349ee89,
    name: "c-cluster-monitoring-alertmanagerconfig-alertmanagerconfigid"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/:id?",
    component: _76dfff93,
    name: "c-cluster-monitoring-route-receiver-id"
  }, {
    path: "/c/:cluster/auth/roles/:resource/create",
    component: _679c4d85,
    name: "c-cluster-auth-roles-resource-create"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig/:alertmanagerconfigid/receiver",
    component: _1abbd888,
    name: "c-cluster-monitoring-alertmanagerconfig-alertmanagerconfigid-receiver"
  }, {
    path: "/c/:cluster/auth/roles/:resource/:id?",
    component: _35ef4d01,
    name: "c-cluster-auth-roles-resource-id"
  }, {
    path: "/c/:cluster/monitoring/monitor/:namespace/:id?",
    component: _aa2ed45e,
    name: "c-cluster-monitoring-monitor-namespace-id"
  }, {
    path: "/c/:cluster/navlinks/:group?",
    component: _1a8e4d87,
    name: "c-cluster-navlinks-group"
  }, {
    path: "/c/:cluster/:product",
    component: _7a4705d5,
    name: "c-cluster-product"
  }, {
    path: "/c/:cluster/:product/members",
    component: _28f1a542,
    name: "c-cluster-product-members"
  }, {
    path: "/c/:cluster/:product/namespaces",
    component: _c69ed6f6,
    name: "c-cluster-product-namespaces"
  }, {
    path: "/c/:cluster/:product/projectsnamespaces",
    component: _347feb02,
    name: "c-cluster-product-projectsnamespaces"
  }, {
    path: "/c/:cluster/:product/:resource",
    component: _781c91f3,
    name: "c-cluster-product-resource"
  }, {
    path: "/c/:cluster/:product/:resource/create",
    component: _99ac11aa,
    name: "c-cluster-product-resource-create"
  }, {
    path: "/c/:cluster/:product/:resource/:id",
    component: _950715ca,
    name: "c-cluster-product-resource-id"
  }, {
    path: "/c/:cluster/:product/:resource/:namespace/:id?",
    component: _d128ba94,
    name: "c-cluster-product-resource-namespace-id"
  }, {
    path: "/",
    component: _8c8e3768,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
