<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import { Checkbox } from '@components/Form/Checkbox';
import { mapGetters } from 'vuex';
import { removeObject } from '@shell/utils/array';
export default {
  name:       'Mount',
  components: { LabeledInput, Checkbox },
  props:      {
    mode: {
      type:    String,
      default: 'create'
    },

    // volume name
    name: {
      type:     String,
      default: ''
    },

    container: {
      type:     Object,
      required: true
    }
  },

  data() {
    const volumeMounts = (this.container.volumeMounts || []).filter(mount => mount.name === this.name);

    return { volumeMounts };
  },

  computed: { ...mapGetters({ t: 'i18n/t' }) },

  watch: {
    volumeMounts(neu) {
      this.container.volumeMounts = (this.container.volumeMounts || []).filter(mount => mount.name && (mount.name !== this.name));
      this.container.volumeMounts.push(...neu);
    },

    name(neu) {
      this.updateMountNames(neu);
    }
  },

  created() {
    if (!this.volumeMounts.length) {
      this.volumeMounts.push({ name: this.name });
    }
  },

  methods: {
    add() {
      this.volumeMounts.push({ name: this.name });
    },

    remove(volumeMount) {
      removeObject(this.volumeMounts, volumeMount);
    },

    updateMountNames(name) {
      this.volumeMounts.forEach((mount) => {
        mount.name = name;
      });
    }
  }
};
</script>

<template>
  <div>
    <div
      v-if="volumeMounts.length"
      class="mount-headers"
    >
      <label>
        {{ t('workload.storage.mountPoint') }}
        <span class="text-error">*</span>
      </label>
      <label>
        {{ t('workload.storage.subPath') }}
      </label>
      <label class="read-only">
        {{ t('workload.storage.readOnly') }}
      </label>
      <!-- <span /> -->
      <label>{{ t('asyncButton.default.action') }}</label>
    </div>
    <div
      v-for="(volumeMount, i) in volumeMounts"
      :key="i"
      class="mount-rows"
    >
      <div>
        <LabeledInput
          :id="`mount-path-${i}`"
          v-model="volumeMount.mountPath"
          :mode="mode"
        />
      </div>
      <div>
        <LabeledInput
          v-model="volumeMount.subPath"
          :mode="mode"
        />
      </div>
      <div class="read-only">
        <Checkbox
          v-model="volumeMount.readOnly"
          :mode="mode"
        />
      </div>
      <div class="remove">
        <button
          v-if="mode!=='view'"
          id="remove-mount"
          type="button"
          class="btn btn-sm role-link"
          @click="remove(volumeMount)"
        >
          {{ t('generic.remove') }}
        </button>
      </div>
    </div>
    <div class="row">
      <button
        v-if="mode!=='view'"
        id="add-mount"
        type="button"
        class="btn role-tertiary adds"
        @click="add()"
      >
        {{ t('workload.storage.addMount') }}
      </button>
    </div>
  </div>
</template>

<style lang='scss'>
.mount-headers, .mount-rows{
  display: grid;
  grid-template-columns: 35% 35% auto 50px;
  grid-gap: $column-gutter;
  margin-bottom: 10px;
  align-items: center;

  .remove {
    color: #0077FF !important;
    // text-align: right;
  }
}

.mount-headers {
  color: var(--input-label);
  background: #F6F6F6;
  border-top: 1px solid #DCDDDE;
  border-bottom: 1px solid #DCDDDE;
  height: 46px;
  line-height: 40px;
  LABEL {
    margin: 0 !important;
    line-height: 20px;
    font-weight: 500;
    color: #161C24;
    padding: 12px 0 12px 12px;
    margin: 0;
    border-right: 1px solid #DCDDDE;
    &:last-child {
      border-right: 0;
    }
  }
}
</style>
