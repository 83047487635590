<script>
import { WORKLOAD_TYPES } from '@shell/config/types';
import Loading from '@shell/components/Loading';
import SortableTable from '@shell/components/SortableTable';
import { _VIEW } from '@shell/config/query-params';
import Collapse from '@shell/components/Collapse.vue';
import ArrayListGrouped from '@shell/components/form/ArrayListGrouped';
import { random32 } from '@shell/utils/string';
import Rule from './Rule';

export default {
  components: {
    ArrayListGrouped, Loading, Rule, SortableTable, Collapse
  },

  props: {
    value: {
      type:    Object,
      default: () => {}
    },

    mode: {
      type:    String,
      default: 'edit'
    },

    certificates: {
      type:    Array,
      default: () => []
    },

    serviceTargets: {
      type:    Array,
      default: () => []
    },

    rules: {
      default: () => ({
        requestHost:   [],
        path:        [],
        port:        [],
        target:      []
      }),
      type: Object,
    }
  },

  async fetch() {
    await Promise.all(Object.values(WORKLOAD_TYPES).map(type => this.$store.dispatch('cluster/findAll', { type })));
  },

  beforeUpdate() {
    for (const rule of this.value.spec.rules) {
      if (!rule.vKey) {
        this.$set(rule, 'vKey', random32(1));
      }
    }
  },

  computed: {
    workloads() {
      return Object.values(WORKLOAD_TYPES).flatMap(type => this.$store.getters['cluster/all'](type));
    },
    isView() {
      return this.mode === _VIEW;
    },
    ruleHeaders() {
      const headers = [
        {
          name:      'fullPath',
          label:     this.t('ingress.rules.headers.path'),
          value:     '',
          formatter: 'IngressFullPath'
        },
        {
          name:          'target',
          label:         this.t('ingress.rules.headers.target'),
          formatter:     'Link',
          formatterOpts: { options: { internal: true }, urlKey: 'targetLink.to' },
          value:         'targetLink',
        },
        {
          name:  'port',
          label: this.t('ingress.rules.headers.port'),
          value: 'port',
        },
        {
          name:       'certs',
          label:      this.t('ingress.rules.headers.certificates'),
          value:      'certs',
          formatter: 'ListLink',
        },
      ];

      if (this.value.showPathType) {
        headers.unshift({
          name:      'pathType',
          label:     this.t('ingress.rules.headers.pathType'),
          value:     'pathType',
        });
      }
      console.log(4444);

      return headers;
    },
    rows() {
      return this.value.createRulesForListPage(this.workloads, this.certificates);
    }
  },
  methods: {
    onAdd() {
      if (this.$refs.lastRule?.focus) {
        this.$refs.lastRule.focus();
      }
    },
    openChange(val, row) {
      const openRow = { ...row, open: val };
      const index = this.value.spec.rules.findIndex(item => item.vKey === row.vKey);

      if (index >= 0) {
        this.$nextTick(() => {
          this.$set(this.value.spec.rules, index, openRow);
        });
      }
    }
  }
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <div v-else-if="isView">
    <SortableTable
      :rows="rows"
      :headers="ruleHeaders"
      key-field="_key"
      :search="false"
      :table-actions="false"
      :row-actions="false"
    />
  </div>
  <div v-else>
    <ArrayListGrouped
      v-model="value.spec.rules"
      :top-button="true"
      :add-label="t('ingress.rules.addRule')"
      :default-add-value="{}"
      :mode="mode"
      @add="onAdd"
    >
      <template #default="props">
        <Collapse
          :key="props.row.value.vKey"
          :open.sync="props.row.value.open"
          class="mb-10"
          @update:open="(val)=>openChange(val, props.row.value)"
        >
          <Rule
            ref="lastRule"
            :key="props.row.value.vKey"
            v-model="props.row.value"
            :service-targets="serviceTargets"
            :ingress="value"
            :rules="rules"
          />
        </Collapse>
      </template>
    </ArrayListGrouped>
  </div>
</template>

<style lang="scss" scoped>
.collapse {
  // background: #FAFBFB;
  ::v-deep .text-link {
    color: #161C24 !important;
  }
  ::v-deep .content {
    background: #ffffff;
  }
}
::v-deep .remove {
  top: 12px !important;
  right:10px !important;
  .role-link {
    color: #96999C !important;
  }
}
::v-deep .labeled-input INPUT.no-label {
  padding: 0;
}

</style>
