<script>

export default {
  name: 'CountBox',

  props:      {
    name: {
      type:     String,
      required: true
    },
    count: {
      type:     Number,
      required: true
    },
    primaryColorVar: {
      type:     String,
      required: true
    },
    compact: {
      type:    Boolean,
      default: false
    },
    color: {
      type:     String,
      required: true
    },
  },
  computed: {
    sideStyle() {
      return `border-top: 3px solid ${ this.customizePrimaryColorOpacity(1) };`;
    },

    mainStyle() {
      return `border-color: ${ this.customizePrimaryColorOpacity(1) };`;
    }
  },

  methods: {
    customizePrimaryColorOpacity(opacity) {
      return `rgba(var(${ this.primaryColorVar }), ${ opacity })`;
    },
    getBackgroundClass(color) {
      switch (color) {
      case 'success':
        return 'status-success';
      case 'info':
        return 'status-info';
      case 'warning':
        return 'status-warning';
      case 'error':
        return 'status-error';
      case 'unknown':
        return 'status-unknown';
      default:
        return '';
      }
    }
  }
};
</script>

<template>
  <div class="count-container" :class="getBackgroundClass(color) ? getBackgroundClass(color) : ''" :style="sideStyle">
    <div class="count" :primary-color-var="primaryColorVar" :style="mainStyle">
      <div class="data" :class="{ 'compact': compact }">
        <h1>{{ count }}</h1>
        <label>{{ name }}</label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
    .count {
      $padding: 10px;

      padding: 10px 10px 10px 24px;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-width: 1px;
      border-style: solid;
      border-top: 0;

      .data {
        display: flex;
        flex-direction: column;
        flex: 1;

        label {
          opacity: 0.7;
        }

        &.compact {
          align-items: baseline;
          flex-direction: row;

          h1 {
            margin-bottom: 0;
            padding-bottom: 0;
          }

          label {
            margin-left: 5px;
            height: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #161C24;
            line-height: 20px;
          }
        }
      }

      h1 {
        padding-bottom: math.div($padding, 2);
        margin-bottom: 5px;
        height: 38px;
        font-size: 30px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #161C24;
        line-height: 38px;
      }

      @media only screen and (min-width: map-get($breakpoints, '--viewport-7')) {
        h1 {
          padding-bottom: math.div($padding, 2);
          margin-bottom: 5px;
          height: 38px;
          font-size: 30px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #161C24;
          line-height: 38px;
        }
      }
    }
    .status-success {
      background-image: url('~shell/assets/images/nav/success.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
    .status-info {
      background-image: url('~shell/assets/images/nav/info.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
    .status-warning {
      background-image: url('~shell/assets/images/nav/warning.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
    .status-error {
      background-image: url('~shell/assets/images/nav/error.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
    .status-unknown {
      background-image: url('~shell/assets/images/nav/unknown.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
</style>
