<script>
import GraphCircle from '@shell/components/graph/Circle';
import GradientBox from '@shell/components/GradientBox';

export default {
  components: { GradientBox, GraphCircle },
  props:      {
    name: {
      type:     String,
      required: true
    },
    total: {
      type:     Number,
      required: true
    },
    useful: {
      type:     Number,
      required: true
    },
    primaryColorVar: {
      type:     String,
      default: null,
    },
    warningCount: {
      type:    Number,
      default: 0
    },
    errorCount: {
      type:    Number,
      default: 0
    },
    location: {
      type:    Object,
      default: null
    },
    plain: {
      type:    Boolean,
      default: false
    },
    graphical: {
      type:    Boolean,
      default: true
    },
    color: {
      type:     String,
      required: true
    },
  },

  computed: {
    percentage() {
      if (this.total === 0) {
        return 0;
      }

      return this.useful / this.total;
    },
    clickable() {
      return !!this.location;
    },
    showAlerts() {
      const total = this.warningCount + this.errorCount;

      return total > 0;
    }
  },
  methods: {
    getBackgroundClass(color) {
      switch (color) {
      case 'success':
        return 'status-success';
      case 'info':
        return 'status-info';
      case 'warning':
        return 'status-warning';
      case 'error':
        return 'status-error';
      case 'unknown':
        return 'status-unknown';
      default:
        return '';
      }
    },
    visitLocation() {
      if (!this.clickable) {
        return;
      }

      this.$router.push(this.location);
    }
  }
};
</script>

<template>
  <GradientBox
    class="count-gauge"
    :class="[getBackgroundClass(color) ? getBackgroundClass(color) : '', 'clickable']"
    :primary-color-var="primaryColorVar"
    :plain="plain"
    @click.native="visitLocation()"
  >
    <div v-if="graphical" class="graphical">
      <GraphCircle v-if="percentage > 0" :primary-stroke-color="`rgba(var(${primaryColorVar}))`" secondary-stroke-color="rgb(var(--resource-gauge-back-circle))" :percentage="percentage" />
      <GraphCircle v-if="percentage === 0" :primary-stroke-color="`rgba(var(${primaryColorVar}))`" secondary-stroke-color="rgb(var(--resource-gauge-back-circle))" class="zero" :percentage="100" />
    </div>
    <div class="data">
      <h1>{{ useful }}</h1>
      <label>{{ name }}</label>
      <div v-if="showAlerts" class="alerts">
        <span class="text-warning">
          <i class="icon icon-warning" /><span class="count">{{ warningCount }}</span>
        </span>
        <span class="text-error">
          <i class="icon icon-error" /><span class="count">{{ errorCount }}</span>
        </span>
      </div>
    </div>
  </GradientBox>
</template>

<style lang="scss">
    .zero {
      circle {
        stroke: var(--gauge-zero);
      }
    }
    .count-gauge {
        $padding: 10px;

        padding: 10px 10px 10px 24px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        &.clickable {
          cursor: pointer;
        }

        .data {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          flex: 1;

          label {
            margin-left: 5px;
            height: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #161C24;
            line-height: 20px;
          }
        }

        .graphical {
          $size: 40px;
          width: $size;
          height: $size;
          margin-right: $padding;
        }

        h1 {
          padding-bottom: math.div($padding, 2);
          margin-bottom: 5px;
          height: 38px;
          font-size: 30px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #161C24;
          line-height: 38px;
        }

        @media only screen and (min-width: map-get($breakpoints, '--viewport-7')) {
          h1 {
            padding-bottom: math.div($padding, 2);
            margin-bottom: 5px;
            height: 38px;
            font-size: 30px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #161C24;
            line-height: 38px;
          }
        }

        .alerts {
            position: absolute;
            right: $padding;
            top: math.div($padding, 2);
            font-size: 15px;

            .text-error {
              margin-left: 5px;
            }
        }

    }
    .status-success {
      background-image: url('~shell/assets/images/nav/deployments/success.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
    .status-info {
      background-image: url('~shell/assets/images/nav/deployments/info.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
    .status-warning {
      background-image: url('~shell/assets/images/nav/deployments/warning.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
    .status-error {
      background-image: url('~shell/assets/images/nav/error.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
    .status-unknown {
      background-image: url('~shell/assets/images/nav/unknown.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
</style>
