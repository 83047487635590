<script>
import SimpleBox from '@shell/components/SimpleBox';
import { COUNT } from '@shell/config/types';
import { colorForState } from '@shell/plugins/dashboard-store/resource-class';

export function colorToCountName(color) {
  switch (color) {
  case 'text-success':
  case 'text-info':
    return 'useful';
  case 'text-warning':
    return 'warningCount';
  default:
    return 'errorCount';
  }
}

export function resourceCounts(store, resource) {
  const inStore = store.getters['currentStore'](COUNT);
  const clusterCounts = store.getters[`${ inStore }/all`](COUNT)?.[0]?.counts;
  const summary = clusterCounts?.[resource]?.summary || {};

  const counts = {
    total:        summary.count || 0,
    useful:       summary.count || 0,
    warningCount: 0,
    errorCount:   0
  };

  Object.entries(summary.states || {}).forEach((entry) => {
    const color = colorForState(entry[0]);
    const count = entry[1];
    const countName = colorToCountName(color);

    counts['useful'] -= count;
    counts[countName] += count;
  });

  return counts;
}
const bgNode = require(`~shell/assets/images/nav/explorer/node.svg`);
const bgDeploy = require(`~shell/assets/images/nav/explorer/deploy.svg`);
const bgResource = require(`~shell/assets/images/nav/explorer/resource.svg`);

export default {
  data() {
    const bgImg = {
      node:     bgNode,
      resource: bgResource,
      deploy:   bgDeploy,
    };

    return { bgImg };
  },
  components: { SimpleBox },

  props:      {
    resource: {
      type:     String,
      default: ''
    },

    spoofedCounts: {
      type:    Object,
      default: null
    },

    cluster: {
      type:    String,
      default: null,
    },

    bgType: {
      type:    String,
      default: null,
    }
  },

  computed: {
    resourceCounts() {
      if (this.spoofedCounts) {
        return this.spoofedCounts;
      }

      return resourceCounts(this.$store, this.resource);
    },

    location() {
      if (this.spoofedCounts) {
        return this.spoofedCounts.location;
      }

      const route = {
        name:     'c-cluster-product-resource',
        params:   {
          product:  this.$store.getters['currentProduct'].name,
          resource: this.resource,
        }
      };

      if (this.cluster) {
        route.params.cluster = this.cluster;
      }

      return route;
    },

    name() {
      if (this.spoofedCounts) {
        return this.spoofedCounts.name;
      }
      const inStore = this.$store.getters['currentStore'](this.resource);
      const schema = this.$store.getters[`${ inStore }/schemaFor`](this.resource);

      return this.$store.getters['type-map/labelFor'](schema, this.resourceCounts.useful);
    },

    backgroundType() {
      return this.bgType;
    }
  },

  methods: {
    goToResource() {
      if (this.location) {
        this.$router.push(this.location);
      }
    },
    getBackgroundClass(type) {
      switch (type) {
      case 'node':
        return 'bg-node';
      case 'resource':
        return 'bg-resource';
      case 'deploy':
        return 'bg-deploy';
      default:
        return '';
      }
    }
  }

};
</script>

<template>
  <div>
    <SimpleBox class="container" :class="[{'has-link': !!location}, getBackgroundClass(backgroundType) ? getBackgroundClass(backgroundType) : '']" @click="goToResource">
      <div class="content-img">
        <img :src="bgImg[bgType]" />
      </div>
      <div class="content-h">
        <h1>{{ resourceCounts.total }}</h1>
        <h3>
          {{ name }}
        </h3>
      </div>

      <div class="warnings">
        <div v-if="resourceCounts.warningCount" class="warn-count mb-10 chip">
          {{ t('clusterIndexPage.sections.warnnings.label') }}: {{ resourceCounts.warningCount }}
        </div>
        <div v-if="resourceCounts.errorCount" class="error-count chip">
          {{ t('clusterIndexPage.sections.abnormal.label') }}: {{ resourceCounts.errorCount }}
        </div>
      </div>
    </SimpleBox>
  </div>
</template>

<style lang='scss' scoped>
  .bg-node {
    background-image: url('~shell/assets/images/nav/node.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 20%;
  }
  .bg-resource {
    background-image: url('~shell/assets/images/nav/resource.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 20%;
  }
  .bg-deploy {
    background-image: url('~shell/assets/images/nav/deploy.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 20%;
  }
  .has-link {
    cursor: pointer;

    &:hover {
      border-color: var(--link);
    }
  }

  ::v-deep .content{
    padding: 0;
    display: flex;
    // justify-content: center;
    flex-direction: row;
    align-items: center;
    position: relative;
    .content-img {
      padding-left: 27px;
    }
    .content-h {
      padding-left: 26px;
      H1 {
        height: 40px;
        font-size: 32px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 40px;
      }
      H3 {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
      }
    }
    & H1, H3 {
        margin: 0;
        color: var(--body-bg);
    }

    & .chip{
      position: absolute;
      top: -15px;
      right: -15px;
      border-radius: 0px 0px 0px 8px;
      height: 24px;
      line-height: 24px;
      width: 73px;
      color: var(--body-bg);
      text-align: center;
      // padding: 0px 1em;

      &.warn-count {
          background: var(--warning);
          border-color: var(--warning);
      }

      &.error-count {
          background: var(--error);
          border-color: var(--error);
      }
    }
}
</style>
